import fetchService from '@/services/fetch';

import { trackError } from './analytics/helpers';

export class HttpError extends Error {
  code = null;

  constructor(message, code) {
    super(message);

    this.code = code;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = response => {
  if (!response?.text) {
    return {};
  }

  return response.text().then(text => (text ? JSON.parse(text) : {}));
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = response => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  trackError(error);

  throw error;
};

/**
 * Checks if a network request is an internal server error
 *
 * @param  {Number} status A response from a network request
 *
 * @return {boolean} Returns a boolean
 */
const isInternalServerError = status => status >= 500 && status <= 511;

// /**
//  * Builds auth headers properly
//  *
//  * @param {string} connectedAsToken
//  * @param {string} authToken
//  * */
// const prepareAuthHeaders = (connectedAsToken, authToken) => {
//   const authHeaders = {};
//
//   if (!isEmpty(connectedAsToken) && !isEmpty(authToken)) authHeaders['Authorization-SA'] = authToken;
//   if (!isEmpty(connectedAsToken) || !isEmpty(authToken)) authHeaders.Authorization = connectedAsToken || authToken;
//
//   return authHeaders;
// }

/**
 * Requests a URL, returning a promise
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {Array<string>} ignoreCodeError Ignore code error
 * @param {Boolean} withErrorResponse To not only return statusText
 *
 * @return {object}           The response data
 */
const request = async (url, options, ignoreCodeError = [], withErrorResponse = false) => {
  //TODO : clear this
  // const connectedAsToken = JSON.parse(getLocalStorage('redux')).auth.connectedAs.token;
  // const authToken = JSON.parse(getLocalStorage('redux')).auth.token;
  const authHeaders = {};
  // const authHeaders = prepareAuthHeaders(connectedAsToken, authToken);

  const h = {
    ...authHeaders,
    ...(options?.headers || {}),
    pragma: options?.headers?.pragma || 'no-cache',
    'cache-control': options?.headers?.['cache-control'] ?? 'no-cache',
  };

  if (h['Content-Type'] !== 'multipart/form-data') {
    h['Content-Type'] = 'application/json';
  } else {
    delete h['Content-Type'];
  }

  const newOptions = {
    ...options,
    credentials: 'include',
    headers: { ...h },
  };

  const request = await fetchService(url, newOptions);

  if (request.ok) {
    if (isJson(request)) {
      return parseJSON(request);
    }
    return await request.blob();
  }

  const error = new HttpError(request.statusText, request.status);

  if (ignoreCodeError.includes(request.status)) {
    throw error;
  }

  trackError(error);

  if (withErrorResponse) {
    throw parseJSON(request);
  }

  throw error;
};

const isJson = request => request.headers.get('Content-Type')?.includes('application/json');

const requestFile = (url, options) => fetch(url, options).then(checkStatus).then(parseJSON);

export { requestFile, checkStatus, parseJSON, isInternalServerError };
export default request;
